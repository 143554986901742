import axios from "axios"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"
import PageTitle from "../components/page-title"
import React, { useState, useEffect } from "react"
import Breadcrumbs from "../components/breadcrumbs"
import { Typography, Container, makeStyles } from "@material-ui/core"

const Policy = ({ pageContext }) => {
  const [t, i18n] = useTranslation()
  const classes = useStyles()
  const { pLang, data } = pageContext

  // console.log(data)

  function refreshPage() {
    windowGlobal.location.reload(false)
  }

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(pLang, `/${i18n.language}/policy`)
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(pLang, `policy`, `/${i18n.language}/policy`)
        // windowGlobal.location.reload()
      } else {
        documentGlobal.location.href = `/${i18n.language}/policy`
      }
    })
  }, [i18n, pLang])

  // if (i18n.on("languageChanged", () => {})) {
  //   console.log(i18n.on())
  //   console.log("languageChanged LOL XD XD XD")
  // }

  const breadcrumbs = [
    {
      text: t("policy.policyTitle"),
    },
  ]

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.GATSBY_API_URL}/options`, {
  //       headers: { "Accept-Language": i18n.language },
  //     })
  //     .then(res => {
  //       console.log(res.data)
  //       // setAboutUsData(res.data.policy)
  //     })
  // }, [i18n])

  return (
    <>
      <SEO title={t("policy.policyTitle")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle title={t("policy.policyTitle")} />
      <Container className={classes.mainContainer}>
        {/* <Typography variant="h6" component="h2">
          {aboutUsData}
        </Typography> */}
        <Typography variant="h6" component="h2">
          <div className={classes.text} dangerouslySetInnerHTML={{ __html: data }} />
        </Typography>
      </Container>
    </>
  )
}
export default Policy

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: "3.5rem",
    marginBottom: "3.5rem",
  },
}))

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document
